import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import './scss/style.scss';
import queryString from 'query-string';
import store from './store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './navigation/_public_routes';
import { IntercomProvider } from 'react-use-intercom';
import { branchController } from './utils';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);
const { FLUZ_INTERCOM_APP_ID } = process.env;

function App() {
  const [referredLinkParams, setReferredLinkParams] = useState(null);

  useEffect(() => {
    async function initApp() {
      const branchControllerResponse: any = await branchController();
      setReferredLinkParams(Object.keys(branchControllerResponse)?.length === 0 ? null : branchControllerResponse);

      const currentURL = new URL(window.location.href);
      if (
        branchControllerResponse?.isUnified &&
        branchControllerResponse?.referrer &&
        !(currentURL.searchParams.get('referrer') && currentURL.searchParams.get('isUnified'))
      ) {
        console.debug('Got a branch link from UWP, store the referrer.');
        const qs = queryString.parse(location.search);
        const referredLinkParams: any = {};
        for (const [key, value] of Object.entries(qs)) {
          referredLinkParams[key] = value;
        }

        referredLinkParams.isUnified = true;
        referredLinkParams.referrer = branchControllerResponse?.referrer;

        const newQuery = new URLSearchParams(referredLinkParams).toString();
        window.location.search = newQuery;
      }
    }

    initApp();
  }, []);

  return (
    <Provider store={store}>
      <IntercomProvider appId={FLUZ_INTERCOM_APP_ID as string} autoBoot>
        <Router>
          <Suspense fallback={loading}>
            <Switch>
              {routes.map((route, idx) => {
                return (
                  !!route.component && (
                    <Route key={idx} path={route.path}>
                      <route.component referredLinkParams={referredLinkParams} />
                    </Route>
                  )
                );
              })}
            </Switch>
          </Suspense>
        </Router>
      </IntercomProvider>
    </Provider>
  );
}

export default App;
